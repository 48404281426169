<template>
  <page-setup>
    <v-container fluid>
      <v-row dense>
        <v-col>
          <div class="text-h5 text-color-main font-weight-black my-4">
            {{ title }}
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <worship-component />
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
const WorshipComponent = () => import("../components/WorshipComponent.vue");

export default {
  components: {
    WorshipComponent
  },
  data: () => ({
    title: "Worships"
  })
};
</script>
